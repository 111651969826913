import styled, { css } from 'styled-components';
import { rem } from 'polished';

type Format = 'default' | 'secondary' | 'negative';

const HoverStyles = css<{ format: Format }>`
    text-decoration: underline;
    text-underline-offset: ${rem(4)};
    color: ${({ theme, format }) =>
        format === 'negative' ? theme.palette.Red : theme.palette.White};

    svg {
        color: ${({ theme, format }) =>
            format === 'negative' ? theme.palette.Red : theme.palette.White};
    }
`;

const TextButton = styled.button<{ format?: Format }>`
    ${({ theme }) => theme.typography.FontMedium}
    font-size: ${rem(13)};
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme, format }) =>
        format === 'secondary' ? theme.palette.LightGrey1 : theme.palette.White};
    transition: color 50ms ease;

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
        margin-right: ${rem(4)};
        color: ${({ theme, format }) =>
            format === 'secondary' ? theme.palette.LightGrey1 : theme.palette.White};
        transition: fill 50ms ease;
    }

    ${({ format, theme }) => {
        switch (format) {
            case 'secondary':
                return css`
                    color: ${theme.palette.LightGrey1};

                    svg {
                        color: ${theme.palette.LightGrey1};
                    }
                `;
            case 'negative':
                return css`
                    color: ${theme.palette.Red};

                    svg {
                        color: ${theme.palette.Red};
                    }
                `;
            default:
                return css`
                    color: ${theme.palette.White};

                    svg {
                        color: ${theme.palette.White};
                    }
                `;
        }
    }}

    &.focus-visible {
        ${HoverStyles}
    }

    @media (hover: hover) {
        &:hover {
            ${HoverStyles}
        }
    }
`;

export default TextButton;

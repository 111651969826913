import Centered from '@internal/ui/legacy/styles/components/Centered';
import { darken } from 'polished';
import { rem } from 'polished';
import styled, { createGlobalStyle, css } from 'styled-components';

export const ScrollLock = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

export const SectionStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 ${rem(32)};
    align-self: center;
    width: 100%;
    box-sizing: border-box;

    & + & {
        margin-top: ${rem(64)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: 0 ${rem(64)};
        align-self: center;
        margin-top: ${rem(88)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        padding: 0 ${rem(100)};
        max-width: ${rem(1280)};

        & + & {
            margin-top: ${rem(104)};
        }
    }
`;

export const ButtonStyles = css`
    height: ${rem(40)};
    min-width: ${rem(40)};
    border-radius: ${rem(12)};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 50ms ease;
    padding: 0 ${rem(20)};
    flex-shrink: 0;
    ${({ theme }) => theme.typography.Heading16}
    white-space: nowrap;

    &:disabled {
        opacity: 0.6;
        cursor: default;
    }

    :focus-visible {
        box-shadow: inset 0px 0px 0px ${rem(1)} ${({ theme }) => theme.palette.White};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        height: ${rem(40)};
        min-width: ${rem(40)};
    }
`;

export const ButtonStyled = styled.button`
    ${ButtonStyles}
`;

export const Heading1Styled = styled.h1`
    ${({ theme }) => theme.typography.Heading40}
    letter-spacing: ${rem(-1)};
    line-height: 106%;
    margin-bottom: ${rem(20)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        letter-spacing: ${rem(-1.7)};
        font-size: ${rem(64)};
        margin-bottom: ${rem(32)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        font-size: ${rem(96)};
        margin-bottom: ${rem(40)};
        letter-spacing: ${rem(-2.5)};
    }
`;

export const Heading2Styled = styled.h2`
    ${({ theme }) => theme.typography.Heading40}
    line-height: 106%;
    letter-spacing: ${rem(-1)};
    margin-bottom: ${rem(16)};
    max-width: ${rem(890)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(52)};
        margin-bottom: ${rem(20)};
        letter-spacing: ${rem(-1.5)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        font-size: ${rem(72)};
        margin-bottom: ${rem(40)};
        letter-spacing: ${rem(-1.8)};
    }
`;

export const Heading3Styled = styled.h3`
    ${({ theme }) => theme.typography.Heading16}
    margin-bottom: ${rem(8)};
    text-align: left;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Heading32}
        letter-spacing: ${rem(-1.3)};
        text-align: left;
        margin-bottom: ${rem(20)};
    }
`;

export const WidgetHeadingStyled = styled.div`
    ${({ theme }) => theme.typography.Heading13Standard}
    margin-bottom: ${rem(8)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Heading20}
        margin-bottom: ${rem(12)};
    }
`;

export const WidgetHeading_Small_Styled = styled.div`
    ${({ theme }) => theme.typography.Heading13Standard}
    margin-bottom: ${rem(4)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Heading16}
        font-size: ${rem(18)};
        margin-bottom: ${rem(6)};
    }
`;

export const WidgetDescriptionStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13}

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${({ theme }) => theme.typography.Paragraph16}
    }
`;

export const DescriptionStyled = styled.p`
    margin-bottom: ${rem(28)};
    ${({ theme }) => theme.typography.Paragraph16};
    line-height: 140%;
    max-width: ${rem(640)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-bottom: ${rem(32)};
        font-size: ${rem(20)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        margin-bottom: ${rem(40)};
    }
`;

export const DarkButtonStyled = styled(ButtonStyled)`
    background-color: rgba(0, 0, 0, 0.3);

    @media (hover: hover) {
        &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.75);
        }
    }
`;

export const OutlineButtonStyled = styled(ButtonStyled)`
    box-sizing: border-box;
    border: ${rem(2)} solid ${({ theme }) => theme.palette.MedGrey4};
    transition: border-color 50ms ease, background-color 50ms ease;

    @media (hover: hover) {
        &:hover:not(:disabled) {
            border-color: ${({ theme }) => theme.palette.MedGrey3};
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
`;

export const RedButtonStyles = css`
    background-color: ${({ theme }) => theme.palette.Red};
    color: ${({ theme }) => theme.palette.White};

    svg {
        height: ${rem(20)};
        width: ${rem(20)};
        margin-left: ${rem(8)};
    }

    @media (hover: hover) {
        &:hover:not(:disabled) {
            background-color: ${({ theme }) => darken(0.15, theme.palette.Red)};
        }
    }
`;

export const RedButtonStyled = styled(ButtonStyled)`
    ${ButtonStyles}
    ${RedButtonStyles}
`;

// export const RedButtonLinkStyled = styled.a`
//     ${ButtonStyles}
//     ${RedButtonStyles}
// `;

export const RedTransparentButtonStyles = css`
    ${ButtonStyles}
    background-color: ${({ theme }) => theme.palette.RedTransparent1};
    color: ${({ theme }) => theme.palette.Red};

    @media (hover: hover) {
        &:hover:not(:disabled) {
            background-color: ${({ theme }) => theme.palette.RedTransparent2};
        }
    }
`;

export const RedTransparentButtonStyled = styled.button`
    ${RedTransparentButtonStyles}
`;

export const MenuButtonStyled = styled(RedTransparentButtonStyled)`
    padding: 0;

    svg {
        height: ${rem(20)};
        width: ${rem(20)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        height: ${rem(54)};
        min-width: ${rem(54)};
    }
`;

export const CloseButtonStyled = styled(MenuButtonStyled)`
    svg {
        color: ${({ theme }) => theme.palette.Red};
        height: ${rem(20)};
        width: ${rem(20)};
    }
`;

export const OverlayCloseButtonStyled = styled(CloseButtonStyled)`
    position: absolute;
    top: ${rem(16)};
    right: ${rem(16)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        top: ${rem(32)};
        right: ${rem(32)};
    }
`;

export const AvatarStyled = styled.div`
    height: ${rem(40)};
    width: ${rem(40)};
    border-radius: 50%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.MedGrey4};
    flex-shrink: 0;
    z-index: 0;

    img,
    svg {
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: ${rem(54)};
        width: ${rem(54)};
    }
`;

export const CheckStyled = styled(Centered)`
    height: ${rem(16)};
    width: ${rem(16)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.Red};
    color: ${({ theme }) => theme.palette.White};
    flex-shrink: 0;

    svg {
        height: ${rem(12)};
        width: ${rem(12)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: ${rem(20)};
        width: ${rem(20)};
        svg {
            height: ${rem(16)};
            width: ${rem(16)};
        }
    }
`;
